/* BATTLE TILE */
.BattleTile {
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 0;
  overflow-x: hidden;
  background: var(--clr-bg);
  border: 1px solid var(--clr-border);
  --corner-radius: 8px;
}

.BattleTile___ongoing {
  border: 2px solid var(--clr-green);
}

.BattleTile___upcoming .BattleTile_content {
  display: block;
}

.BattleTile_leaders {
  padding: 1rem;
  background: var(--clr-box-bg);
  border-radius: 5px;
  margin: 1rem 0 0;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BattleTile_info h4 {
  margin-right: 1rem;
  text-transform: uppercase;
  font-size: var(--font-size-0);
}

.BattleTile_countdown {
  color: var(--clr-text-lightest);
}

.BattleTile_content {
  display: flex;
  overflow-x: auto;
}

.BattleTile_contentTimeAttack {
  justify-content: space-between;
  gap: 3rem;
}

.BattleTile_info {
  flex-shrink: 0;
  flex-basis: 33ch;
}

.BattleTile_infoNoBorder {
  border-right: 0;
}

.BattleTile_targets,
.BattleTile_battles {
  white-space: nowrap;
  padding: 2rem 2rem 2rem 0;
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(50% - 2rem);
  display: flex;
  gap: 1rem;
}

.BattleTile_target {
  position: relative;
  display: inline-block;

  /* HACK: 10px is there because the build is converting 0px to 0, which is invalid */
  /* height: clamp(10px, 24vw, 220px); */

  vertical-align: middle;

  transition: transform 0.25s ease;

  aspect-ratio: var(--target-aspect-ratio);
}
/* locked targets are not anchor tags */
.BattleTile_target:not(a) {
  pointer-events: none;
}
.BattleTile_target:hover {
  transform: scale(1.05);
}

.BattleTile_target > img {
  aspect-ratio: var(--target-aspect-ratio);
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.BattleTile_target_info {
  top: 0.3rem;
  right: 0.3rem;
}

.BattleTile_TimeAttackTile {
  border-radius: 16px;
  background: var(--clr-target-tile-bg);
  padding: 1.5rem;
  margin-left: 0;
  white-space: normal;
}

.BattleTile_TimeAttackTile__Title {
  color: var(--clr-brand);
  font-size: var(--font-size-2);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.BattleTile_TimeAttackTile__Body {
  font-size: var(--font-size-0);
  max-width: 40ch;
}

@media (max-width: 600px) {
  .BattleTile_content {
    flex-direction: column;
  }
  .BattleTile_info {
    flex-grow: 1;
  }
}
