.battleMode {
  display: inline-block;
  margin-bottom: 0;
  padding: 0.75rem;
  background: var(--clr-box-bg-darker);
  border-bottom: 1px solid var(--clr-border);
}

.versusBattleMode {
  background: var(--clr-box-bg-darker);
  border: 0;
}

.battleMode p {
  line-height: 1.2;
}

.battleModeLarge {
  font-family: 'ClashGrotesk';
  font-weight: 500;
  position: relative;
  padding: 1rem;
}

.gameModeLabel {
  height: 3rem;
  width: 3rem;
  max-height: 3rem;
  max-width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--clr-bg);
  border: 1px solid var(--clr-border);
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
}

.gameModeLabelDesc {
  color: var(--clr-text-lightest-final);
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: var(--clr-text-lightest-final);
}
